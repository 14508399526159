import { find, isPlainObject } from 'lodash';
import eventHelpers from '@/services/helpers/event';

const Sport = (sport) => {
  if (!isPlainObject(sport)) return null;

  return {
    id: sport.sportId,
    name: sport.sportName,
    label: sport.sportLabel,
  };
};

const Season = (season) => {
  if (!isPlainObject(season)) return null;

  return {
    id: season.seasonId,
    name: season.seasonName,
  };
};

const Competition = (competition) => {
  if (!isPlainObject(competition)) return null;

  return {
    id: competition.competitionId,
    name: competition.competitionName,
  };
};

const Region = (region) => {
  if (!isPlainObject(region)) return null;

  return {
    id: region.regionId,
    name: region.regionName,
  };
};

const Competitor = (competitors, { side }) => {
  let competitor = find(competitors || [], { side });
  if (!isPlainObject(competitor)) {
    if (!competitors?.length) return null;
    // This is a fallback for the cases where we don't get 'side' property inside of the competitors array.
    competitor = side === 'HOME' ? competitors[0] : competitors[1];
  }

  return {
    id: competitor.competitorId,
    name: competitor.name,
    shortName: competitor.shortName || side,
    side: competitor.side || side,
  };
};

const SportTemplate = (sportTemplate) => {
  if (!isPlainObject(sportTemplate)) return null;

  return {
    id: sportTemplate.sportTemplateId,
    name: sportTemplate.name,
  };
};

export default (data, selectedFeed) => {
  if (!isPlainObject(data)) return null;

  return {
    country: data.country || '',
    id: data.eventId || '',
    name: data.eventName || '',
    hasInPlay: data.hasInPlay || false,
    startsAt: data.startsAt || '',
    status: data.status || '',
    seasonId: data.seasonId || '',
    sportId: data.sportId || '',
    matchState: data.matchState || 'N_A',
    isUSAView: data.isUsaView || false,
    limbo: data.limbo || false,
    sport: Sport(data.sport),
    season: Season(data.season),
    competition: Competition(data.competition),
    competitors: data.competitors,
    region: Region(data.region),
    home: Competitor(data.competitors, { side: 'HOME' }),
    away: Competitor(data.competitors, { side: 'AWAY' }),
    isBooked: !!data.booked?.nodes?.length,
    pricingMode: data.booked?.nodes?.[0]?.pricingMode || '',
    hasExternalPrices: data.eventHasExternalPricesByEventId?.nodes?.[0]?.hasExternalPrices,
    hasInternalPrices: data.eventHasInternalPricesByEventId?.nodes?.[0]?.hasInternalPrices,
    hasDpsPrices: data.eventHasDpsPricesByEventId?.nodes?.[0]?.hasDpsPrices,
    isMapped: data.eventIsMappedsByEventId?.nodes?.[0]?.isMapped,
    sportTemplate: SportTemplate(data.appliedTemplates?.nodes?.[0]?.sportTemplate),
    isSuspended: !!data.operatorEventSuspensionsByEventId?.nodes?.length,
    isHighlighted: !!data.eventHighlightsByEventId?.nodes?.length,
    isCovered: !!data.eventIsCoveredsByEventId?.nodes?.[0]?.available,
    isReplay: data.eventType === 'REPLAY',
    source: data.source,
    limitConfiguration: data.limitConfiguration?.nodes?.[0]?.limitConfiguration || null,
    detailsSource: selectedFeed || eventHelpers.getEventDetailsSource(data),
    pricesApproved: !!data?.eventReadiness?.isApproved,
  };
};
